<template>
  <b-card title="">
    <h3 class="text-primary">Cloud Profiles</h3>
    <b-row>
      <b-col md="6" class="mb-1"> </b-col>
      <b-col md="5" class="mb-1">
        <!-- <b-form-group
          label-cols-sm="5"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Search"
              style="padding: 18px 9px"
            />
          </b-input-group>
        </b-form-group> -->
      </b-col>
      <b-col md="1" class="mb-1">
        <b-form-group>
          <b-button
            v-if="$store.state.app.user.permissions.includes('cloudProfile.add_cloudprofile')"
            class="btn btn-success"
            @click="gotoAddCloudProfile()"
            size="sm"
          >
            Add +
          </b-button>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          bordered
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="cloudTable"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
          <!-- <template #cell(group)="data">
            <b-badge
              variant="primary"
              v-for="item in data.item.groups"
              :key="item"
            >
              {{ item.name }}
            </b-badge>
          </template> -->
          <template #cell(action)="data">
            <b-button
              v-if="$store.state.app.user.permissions.includes('cloudProfile.change_cloudprofile')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon rounded-circle"
              @click="gotoEditCloudProfile(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="$store.state.app.user.permissions.includes('cloudProfile.delete_cloudprofile')"
              variant="gradient-danger"
              class="ml-1 btn-icon rounded-circle"
              @click="showModal(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-row>
          <b-col md="2" sm="4">
            <b-form-group class="mb-0">
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col md="10" sm="4">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      id="modal-danger"
      ref="modal-danger"
      ok-only
      ok-variant="danger"
      @ok="deleteCloudProfile(cloudprofileid)"
      modal-class="modal-danger"
      centered
      title="Delete !"
    >
      <b-card-text>
        <!-- {{this.cloudprofileid}} -->
        Are You Sure to Delete the Cloud Profile ?
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardText,
  BTable,
  BAvatar,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  VBModal,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BAvatar,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      cloudTable: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        "#",
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "profile_type",
          label: "Profile Type",
        },
        // {
        //   key: "firewall_id",
        //   label: "Firewall ID",
        // },
        // {
        //   key: "access_key",
        //   label: "Access Key",
        // },
        // {
        //   key: "secret_key",
        //   label: "Secret Key",
        // },
        { key: "action", label: "Action", sortable: true },
      ],
      items: [],
      actions: [
        {
          1: "primary",
          2: "danger",
        },
      ],
      cloudprofileid: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    rows() {
      return this.cloudTable.length;
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.getCloudList();
  },
  created() {
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
  },
  methods: {
    showModal(itemid) {
      this.$refs["modal-danger"].show();
      this.cloudprofileid = itemid;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    gotoAddCloudProfile() {
      this.$router.push("/eca-createcloudprofile");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getCloudList: function () {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + `cloudprofile/cloudprofile`,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.cloudTable = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoEditCloudProfile(id) {
      this.$router.push(`/eca-editcloudprofile/${id}`);
    },

    deleteCloudProfile(cloudprofileid) {
      const data = {
        id: cloudprofileid,
      };
      const options = {
        method: "DELETE",
        data: data,
        // headers: { Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`, "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `cloudprofile/cloudprofile/${cloudprofileid}/`,
      };
      this.$http(options)
        .then((res) => {
          this.getCloudList();
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #7367f0 !important;
  color: #fff !important;
}
[dir] .table th,
[dir] .table td {
  padding: 0.72rem 2rem;
  text-align: center;
}
</style>
